
import s0 from '~/.nuxt/gb-cms/sections/ArtSection'
import s1 from '~/.nuxt/gb-cms/sections/ArtistBiography'
import s2 from '~/.nuxt/gb-cms/sections/BackgroundImageSection'
import s3 from '~/.nuxt/gb-cms/sections/FilterComponent'
import s4 from '~/.nuxt/gb-cms/sections/GalleryOneRow'
import s5 from '~/.nuxt/gb-cms/sections/GallerySection'
import s6 from '~/.nuxt/gb-cms/sections/GallerySingle'
import s7 from '~/.nuxt/gb-cms/sections/GalleryTwoRows'
import s8 from '~/.nuxt/gb-cms/sections/SingleImage'
import s9 from '~/.nuxt/gb-cms/sections/HeroSection'
import s10 from '~/.nuxt/gb-cms/sections/HorizontalSlider'
import s11 from '~/.nuxt/gb-cms/sections/ContactSection'
import s12 from '~/.nuxt/gb-cms/sections/FooterContact'
import s13 from '~/.nuxt/gb-cms/sections/PrivacyPolicy'
import s14 from '~/.nuxt/gb-cms/sections/TimelineSection'

const sectionMapper = {
	'art-section': s0,
	'artist-biography': s1,
	'background-image-section': s2,
	'filter-component': s3,
	'gallery-one-row': s4,
	'gallery-section': s5,
	'gallery-single': s6,
	'gallery-two-rows': s7,
	'single-image': s8,
	'hero-section': s9,
	'horizontal-slider': s10,
	'contact-section': s11,
	'footer-contact': s12,
	'privacy-policy': s13,
	'timeline-section': s14
}

export default sectionMapper
