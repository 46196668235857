
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        infinite: true,
        dots: false,
        arrows: true,
        autoplay: false,
        rtl: true,
        speed: 500,
        slidesToShow: 1,
        rows: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: true,
              arrows: false,
              rtl: false,
            },
          },
        ],
      },
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },

        {
          name: 'description',
          label: 'Opis',
          type: 'text',
        },
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '400x400',
        },
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
      ],
    }
  },
}
