
export default {
  methods: {
    scrollUnder() {
      const element = document.getElementById('box')
      let y = element.scrollHeight
      document.body.scrollTop = y
      document.documentElement.scrollTop = y
    },
  },
}
