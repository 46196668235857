
export default {
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
      ],
    }
  },
  methods: {
    goBack() {
      history.back()
    },
  }
}
