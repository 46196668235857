
import { mapState } from 'vuex'
export default {
  data() {
    return {
      items: [],
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },

        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '400x400',
        },
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
        {
          name: 'category',
          label: 'Kategorija',
          type: 'text',
        },
      ],
    }
  },
  computed: {
    ...mapState(['activeCategory']),
    filteredItems: function () {
      if (!this.activeCategory) return this.items
      return this.items.filter(
        (item) =>
          item.category === this.activeCategory.category ||
          item.category.toUpperCase() ===
            this.activeCategory.category.toUpperCase()
      )
    },
  },
  methods: {
    addClass(e) {
      const element = e.target
      if (!element.classList.contains('stuff')) {
        element.classList.add('stuff')
      }
    },
    removeClass(e) {
      const element = e.target
      if (element.classList.contains('stuff')) {
        element.classList.remove('stuff')
      }
    },
  },
}
