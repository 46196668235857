
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      categories: [],
      filterOpen: false,
      options: [
        {
          name: 'category',
          label: 'Kategorija',
          type: 'text',
        },
      ],
    }
  },
  computed: {
    ...mapState(['activeCategory']),
  },
  async mounted() {
    await this.$nextTick()
    if (this.categories) {
      this.updateCategories(this.categories)
      this.updateActiveCategory(null)
    }
  },

  methods: {
    ...mapActions(['updateCategories', 'updateActiveCategory']),
    toggleFilters() {
      this.filterOpen = !this.filterOpen
    },
  },
}
