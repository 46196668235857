import { render, staticRenderFns } from "./GallerySingle.vue?vue&type=template&id=5a495ef6"
import script from "./GallerySingle.vue?vue&type=script&lang=js"
export * from "./GallerySingle.vue?vue&type=script&lang=js"
import style0 from "./GallerySingle.vue?vue&type=style&index=0&id=5a495ef6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,GalleryImageComponent: require('/home/node/app/components/gallery/ImageComponent.vue').default})
