import { render, staticRenderFns } from "./FooterContact.vue?vue&type=template&id=56288b71"
import script from "./FooterContact.vue?vue&type=script&lang=js"
export * from "./FooterContact.vue?vue&type=script&lang=js"
import style0 from "./FooterContact.vue?vue&type=style&index=0&id=56288b71&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButtonComponent: require('/home/node/app/components/ui/ButtonComponent.vue').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default})
