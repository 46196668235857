import { render, staticRenderFns } from "./BackgroundImageSection.vue?vue&type=template&id=568adddc"
var script = {}
import style0 from "./BackgroundImageSection.vue?vue&type=style&index=0&id=568adddc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default})
