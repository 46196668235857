
export default {
  mounted() {
    this.$nextTick(() => {
      const body = document.documentElement
      body.classList.add('contact-page')
    })
  },
  destroyed() {
    const body = document.documentElement
    body.classList.remove('contact-page')
  },
}
